import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

// import { CssBaseline } from '@material-ui/core/CssBaseline';
import NewLead from './pages/newLead';
import './App.css';

import theme from './theme';

class App extends Component {
    state = {
        company: undefined,
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>

                <Router>
                    <React.Fragment>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            {/* <CssBaseline /> */}
                            <Route exact path="/" component={NewLead} />
                        </MuiPickersUtilsProvider>
                    </React.Fragment>
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default App;
