import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Checkbox,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    TextField,
    Select,
    Snackbar,
    Typography,
    // SnackbarContent,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark,
    },
    snackbarIcon: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        fontSize: 20,
    // margin: theme.spacing.unit,
    },
    snackbarMessage: {
        fontSize: 'inherit',
        // marginBottom: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    paper: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    formControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 1,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        fontWeight: 500,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bumpTop2Unit: {
        marginTop: theme.spacing.unit * 2,
    },
});

const titles = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mx',
];

const ShortForm = ({
    classes,
    updateLeadData,
    lead,
    saveLead,
    loading,
    errors,
    showGDPRDialog,
    tickGDPRClick,
}) => (
    <React.Fragment>
        <Typography align="center">
Enter your details below and Pay Weekly will contact you shortly
            {' '}
        </Typography>
        <Paper className={classes.paper}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.title}
                    >
                        <InputLabel htmlFor="lead-title" className={classes.inputLabel}>Title</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={updateLeadData}
                                    name="title"
                                    id="lead-title"
                                />
                            )}
                            value={lead.title || ''}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {titles.map(title => (
                                <MenuItem
                                    key={title}
                                    value={title}
                                >
                                    {title}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.title}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        onChange={updateLeadData}
                        name="firstname"
                        id="firstname"
                        label="Firstname"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.firstname}
                        helperText={errors.firstname}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        onChange={updateLeadData}
                        name="surname"
                        id="surname"
                        label="Surname"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.surname}
                        helperText={errors.surname}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        onChange={updateLeadData}
                        name="postcode"
                        id="postcode"
                        label="Postcode"
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        required
                        error={!!errors.postcode}
                        helperText={errors.postcode}
                    />
                </Grid>
            </Grid>
            <Divider className={classes.formDivider} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        onChange={updateLeadData}
                        className={classes.formControl}
                        name="homeTelephoneNumber"
                        id="homeTelephoneNumber"
                        label="Home Phone"
                        margin="normal"
                        variant="outlined"
                        type="tel"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        onChange={updateLeadData}
                        id="mobileTelephoneNumber"
                        name="mobileTelephoneNumber"
                        label="Mobile Phone"
                        margin="normal"
                        variant="outlined"
                        type="tel"
                        fullWidth
                        required
                        error={!!errors.mobileTelephoneNumber}
                        helperText={errors.mobileTelephoneNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        onChange={updateLeadData}
                        id="emailAddress"
                        name="emailAddress"
                        label="Email Address"
                        margin="normal"
                        variant="outlined"
                        type="email"
                        fullWidth
                        required
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress}
                    />
                </Grid>
            </Grid>
            <Divider className={classes.formDivider} />

            <FormControlLabel
                control={(
                    <Checkbox
                        onChange={updateLeadData}
                        name="gdpr"
                        id="gdpr"
                        value="1"
                        color="primary"
                    />
                )}
                label="Tick box if you want us to send special offers and product information."
            />
            {/* <Snackbar
            open={this.state.snackbarOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={5000}
            message={(
                <span className={classes.snackbarMessage}>
                    <WarningIcon className={classes.snackbarIcon} />
                    {this.state.error}
                </span>
            )}
            ContentProps={{ classes: { root: classes.snackbar } }}
            onClose={this.handleSnackbarClose}
        /> */}
            {/* { this.state.error && <SnackbarContent variant="error" message={this.state.error} />} */}
            {/* </Snackbar> */}
            <Button
                type="button"
                onClick={saveLead}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
            >
                { loading ? 'Saving...' : 'Save' }
            </Button>
        </Paper>
        <Dialog
            open={showGDPRDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogContentContainer}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Before you continue....
                        </Typography>
                        <DialogContentText>
                                We would like to send you information about our Competitions, Offers & Services.
                        </DialogContentText>
                        <DialogContentText className={classes.bumpTop2Unit}>
                                Is that OK?
                        </DialogContentText>
                        <form>
                            <Button
                                onClick={tickGDPRClick}
                                variant="contained"
                                className={classes.dialogButton}
                            >
                                Yes
                            </Button>
                        </form>
                    </React.Fragment>
                </div>
                <Divider />
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={saveLead}
                        color="primary"
                        autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>

    </React.Fragment>
);

const formShape = {
    title: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    townCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    homeTelephoneNumber: PropTypes.string,
    mobileTelephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    gdpr: PropTypes.string,
};

ShortForm.propTypes = {
    classes: PropTypes.object.isRequired,
    updateLeadData: PropTypes.func.isRequired,
    lead: PropTypes.shape(formShape).isRequired,
    errors: PropTypes.shape(formShape).isRequired,
    saveLead: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ShortForm);
