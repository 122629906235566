import React from 'react';

import {
    CssBaseline,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import PropTypes from 'prop-types';

import pwcLogo from '../images/logo-main.png';
import compbanner from '../images/compbanner.jpg';
import PromoForm from '../components/PromoForm';
import ShortForm from '../components/ShortForm';
// import fetch from '../utilities/fetch';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    pwcLogo: {
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        maxHeight: 120,
        marginBottom: theme.spacing.unit * 2,
    },
    compbanner: {
        textAlign: 'center',
        maxWidth: '100%',
    },
    compbannerContainer: {
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        // maxHeight: 140,
        maxWidth: '100%',
        marginBottom: theme.spacing.unit * 2,
    },
    thankYou: {
        marginTop: theme.spacing.unit * 4,
    },
});

class Promo extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        lead: {},
        saved: false,
        errors: {},
        leadRedirectURL: null,
        showGDPRDialog: false,
    }

    componentDidMount() {
        // Temporarily stting default to sourceCode 1001
        const { sc = 1001 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        // Temporarily stting default to CampaignCode 1000
        const { cc = 1000 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        this.setState({
            sc,
            cc,
        });
    }

    handleSnackbarClose = (event, reason) => {
        this.setState({
            snackbarOpen: false,
        });
    }

    titalise = string => string.toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')

    updateLeadData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        default:
            formattedValue = this.titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            lead: {
                ...this.state.lead,
                [name]: formattedValue,
            },
        });
    }

    validate = () => {
        const { lead } = this.state;
        let fieldOrder;
        let title;
        let firstname;
        let surname;
        let address1;
        let townCity;
        let postcode;
        let county;
        let country;
        let mobileTelephoneNumber;
        let emailAddress;
        let errors;
        let firstError;

        switch (this.state.cc) {
        case '1006':
            fieldOrder = [
                'title',
                'firstname',
                'surname',
                'postcode',
                'mobileTelephoneNumber',
                'emailAddress',
            ];

            title = (() => {
                switch (true) {
                case !lead.title: return 'Title required';
                default: return '';
                }
            })();
            firstname = (() => {
                switch (true) {
                case !lead.firstname: return 'Firstname required';
                case lead.firstname.length < 2: return 'Firstname too short';
                case lead.firstname.length > 100: return 'Firstname too long';
                default: return '';
                }
            })();
            surname = (() => {
                switch (true) {
                case !lead.surname: return 'Surname required';
                case lead.surname.length < 2: return 'Surname too short';
                case lead.surname.length > 100: return 'Surname too long';
                default: return '';
                }
            })();
            postcode = (() => {
                switch (true) {
                case !lead.postcode: return 'Postcode required';
                case lead.postcode.length < 2: return 'Postcode too short';
                case lead.postcode.length > 100: return 'Postcode too long';
                default: return '';
                }
            })();
            mobileTelephoneNumber = (() => {
                switch (true) {
                case !lead.mobileTelephoneNumber: return 'Mobile phone required';
                case lead.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
                case lead.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
                default: return '';
                }
            })();
            emailAddress = (() => {
                switch (true) {
                case !lead.emailAddress: return 'Email address required';
                case lead.emailAddress.length < 2: return 'Email address too short';
                case lead.emailAddress.length > 100: return 'Email address too long';
                case !this.validateEmail(lead.emailAddress): return 'Invalid email address';
                default: return '';
                }
            })();

            errors = removeFalseyValuesFromObject({
                title,
                firstname,
                surname,
                postcode,
                mobileTelephoneNumber,
                emailAddress,
            });

            firstError = fieldOrder.find(field => !!errors[field]);

            this.setState({
                errors,
                firstError,
            });

            if (isEmpty(errors)) return true;

            return false;
        default:
            fieldOrder = [
                'title',
                'firstname',
                'surname',
                'address1',
                'townCity',
                'county',
                'postcode',
                'country',
                'mobileTelephoneNumber',
                'emailAddress',
            ];

            title = (() => {
                switch (true) {
                case !lead.title: return 'Title required';
                default: return '';
                }
            })();
            firstname = (() => {
                switch (true) {
                case !lead.firstname: return 'Firstname required';
                case lead.firstname.length < 2: return 'Firstname too short';
                case lead.firstname.length > 100: return 'Firstname too long';
                default: return '';
                }
            })();
            surname = (() => {
                switch (true) {
                case !lead.surname: return 'Surname required';
                case lead.surname.length < 2: return 'Surname too short';
                case lead.surname.length > 100: return 'Surname too long';
                default: return '';
                }
            })();
            address1 = (() => {
                switch (true) {
                case !lead.address1: return 'Address required';
                case lead.address1.length < 2: return 'Address too short';
                case lead.address1.length > 100: return 'Address too long';
                default: return '';
                }
            })();
            townCity = (() => {
                switch (true) {
                case !lead.townCity: return 'Town/City required';
                case lead.townCity.length < 2: return 'Town/City too short';
                case lead.townCity.length > 100: return 'Town/City too long';
                default: return '';
                }
            })();
            postcode = (() => {
                switch (true) {
                case !lead.postcode: return 'Postcode required';
                case lead.postcode.length < 2: return 'Postcode too short';
                case lead.postcode.length > 100: return 'Postcode too long';
                default: return '';
                }
            })();
            county = (() => {
                switch (true) {
                case !lead.county: return 'County required';
                default: return '';
                }
            })();
            country = (() => {
                switch (true) {
                case !lead.country: return 'Country required';
                default: return '';
                }
            })();
            mobileTelephoneNumber = (() => {
                switch (true) {
                case !lead.mobileTelephoneNumber: return 'Mobile phone required';
                case lead.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
                case lead.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
                default: return '';
                }
            })();
            emailAddress = (() => {
                switch (true) {
                case !lead.emailAddress: return 'Email address required';
                case lead.emailAddress.length < 2: return 'Email address too short';
                case lead.emailAddress.length > 100: return 'Email address too long';
                case !this.validateEmail(lead.emailAddress): return 'Invalid email address';
                default: return '';
                }
            })();

            errors = removeFalseyValuesFromObject({
                title,
                firstname,
                surname,
                address1,
                townCity,
                postcode,
                county,
                country,
                mobileTelephoneNumber,
                emailAddress,
            });

            firstError = fieldOrder.find(field => !!errors[field]);

            this.setState({
                errors,
                firstError,
            });

            if (isEmpty(errors)) return true;

            return false;
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    saveLead = async (e) => {
        if (e) e.preventDefault();
        console.log('Entered saveLead()');
        let body;
        let response;

        if (this.validate() === false) return;

        if (this.state.lead.gdpr !== '1' && !this.state.showGDPRDialog) {
            console.log('Showing GDPR Dialog');
            this.setState({
                showGDPRDialog: true,
            });
            return;
        }

        console.log('Saving lead');
        console.log('GDPR: ', this.state.lead.gdpr);

        // // Temporarily stting default to sourceCode 1001
        // const { sc = 1001 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        // // Temporarily stting default to CampaignCode 1000
        // const { cc = 1000 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

// Temporarily stop disabling save button
        // this.setState({
        //     loading: true,
        // });

        response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/lead', {
            method: 'POST',
            body: JSON.stringify({
                ...this.state.lead,
                sc: this.state.sc,
                cc: this.state.cc,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        if (response.status !== 200) {
            this.setState({
                error: response.error,
                loading: false,
                snackbarOpen: true,
            });
        } else {
            console.log(body);
            const leadRedirectURL = body.leadRedirectURL;
            this.setState({
                leadRedirectURL,
                saved: true,
            });
            if (leadRedirectURL) {
                setTimeout(() => { window.location.href = leadRedirectURL; }, 3000);
            }
            if (this.state.cc === '1006') {
            const emailFrom = 'Pay Weekly - DO NOT REPLY <donotreply@payweeklycarpets.co.uk>';
            const emailSubject = 'Disneyland Paris Prize Draw ';
//             const emailMessage = `
// Hello ${this.state.lead.firstname},
//
// Thank you for entering our Disneyland Paris prize draw.
//
// You will be notified if you are successful.
//
// Good Luck!
// `;
const emailMessage = `
<table id="defanged8-bodyTable" style="margin-left: auto; margin-right: auto;" border="0" width="100%" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td id="defanged8-bodyCell" align="left" valign="top">
<table class="defanged8-templateContainer" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td id="defanged8-templateHeader" valign="top">&nbsp;</td>
</tr>
<tr>
<td id="defanged8-templateBody" valign="top">
<table class="defanged8-mcnImageBlock" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody class="defanged8-mcnImageBlockOuter">
<tr>
<td class="defanged8-mcnImageBlockInner" valign="top">
<table class="defanged8-mcnImageContentContainer" border="0" width="100%" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr>
<td class="defanged8-mcnImageContent" valign="top"><img class="defanged8-mcnImage" style="display: block; margin-left: auto; margin-right: auto;" src="https://pwc-email-images.s3-eu-west-1.amazonaws.com/compbanner.jpg" alt="" width="564" align="center" /></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="defanged8-mcnTextBlock" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody class="defanged8-mcnTextBlockOuter">
<tr>
<td class="defanged8-mcnTextBlockInner" valign="top">
<table class="defanged8-mcnTextContentContainer" border="0" width="100%" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr>
<td class="defanged8-mcnTextContent" valign="top">
<h3 style="text-align: center;"><br />Thank you for entering our Disneyland prize draw</h3>
<p style="text-align: center;">We will notify you if you are successful.<br />&nbsp;</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="defanged8-mcnImageBlock" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody class="defanged8-mcnImageBlockOuter">
<tr>
<td class="defanged8-mcnImageBlockInner" valign="top">
<table class="defanged8-mcnImageContentContainer" border="0" width="100%" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr>
<td class="defanged8-mcnImageContent" valign="top"><img class="defanged8-mcnImage" style="display: block; margin-left: auto; margin-right: auto;" src="https://pwc-email-images.s3-eu-west-1.amazonaws.com/GoodLuck.jpg" alt="" width="300" align="center" /></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
`;
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${body.contactCode}/sendMessage`, {
                method: 'POST',
                body: JSON.stringify({
                    type: 'email',
                    from: emailFrom,
                    subject: emailSubject,
                    message: emailMessage,
                    format: 'html',
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
        }

        // if (response.error) {
        //     this.setState({
        //         error: response.error,
        //         loading: false,
        //         snackbarOpen: true,
        //     });
        // } else {
        //     this.props.history.push('/searchPage');
        // }
    }

    tickGDPRClick = () => {
        console.log('Ticking GDPR');
        this.setState(state => ({
            lead: {
                ...state.lead,
                gdpr: '1',
            },
        }), () => this.saveLead());
    }

    // render = () => {
    //     const { classes } = this.props;
    //     const { saved, leadRedirectURL } = this.state;
    //
    //     return (
    //         <main className={classes.main}>
    //             <CssBaseline />
    //             <Grid
    //                 container
    //                 spacing={0}
    //                 direction="column"
    //                 alignItems="center"
    //                 justify="center"
    //             >
    //                 <Grid item xs={12}>
    //                     <img src={pwcLogo} className={classes.pwcLogo} alt="logo" />
    //                 </Grid>
    //             </Grid>
    //             { saved ? (
    //                 <React.Fragment>
    //                     <Typography variant="h4" gutterBottom align="center" className={classes.thankYou}>
    //                         Thank you!
    //                     </Typography>
    //                     {!leadRedirectURL
    //                         ? (
    //                             <Typography align="center">
    //                                 We will be in-touch shortly.
    //                             </Typography>
    //                         )
    //                         : (
    //                             <Typography align="center">
    //                                 You will be redirected to our finance partner shortly.
    //                             </Typography>
    //                         )
    //                     }
    //                 </React.Fragment>
    //             ) : (
    //                 <PromoForm
    //                     updateLeadData={this.updateLeadData}
    //                     lead={this.state.lead}
    //                     saveLead={this.saveLead}
    //                     loading={this.state.loading}
    //                     errors={this.state.errors}
    //                 />
    //
    //             )}
    //             {this.state.firstError && `error with the ${this.state.firstError} field`}
    //         </main>
    //     );
    // }

    render() {
        const { classes } = this.props;
        const { saved, leadRedirectURL } = this.state;

        const scToken = 5;

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid
                        item xs={12}
                        spacing={0}
                        >
                        {(() => {
                            switch (true) {
                                case this.state.cc === '1006': {
                                    return (
                                        <div className={classes.compbannerContainer}>
                                            <img src={compbanner} className={classes.compbanner} alt="logo" />
                                        </div>
                                    );
                                }
                                default: {
                                    return (
                                        <img src={pwcLogo} className={classes.pwcLogo} alt="logo" />
                                    );
                                }
                            }
                        })()}
                    </Grid>
                </Grid>

                {(() => {
                    switch (true) {
                    case saved: {
                        return (
                            <React.Fragment>
                                <Typography variant="h4" gutterBottom align="center" className={classes.thankYou}>
                                Thank you!
                                </Typography>
                                {!leadRedirectURL
                                    ? (
                                        <Typography align="center">
                                        We will be in-touch shortly.
                                        </Typography>
                                    )
                                    : (
                                        <Typography align="center">
                                        You will be redirected to our finance partner shortly.
                                        </Typography>
                                    )
                                }
                            </React.Fragment>
                        );
                    }
                    case this.state.cc === '1006': {
                        return (
                            <ShortForm
                                updateLeadData={this.updateLeadData}
                                lead={this.state.lead}
                                saveLead={this.saveLead}
                                loading={this.state.loading}
                                errors={this.state.errors}
                                showGDPRDialog={this.state.showGDPRDialog}
                                tickGDPRClick={this.tickGDPRClick}
                            />
                        );
                    }
                    default: {
                        return (
                            <PromoForm
                                updateLeadData={this.updateLeadData}
                                lead={this.state.lead}
                                saveLead={this.saveLead}
                                loading={this.state.loading}
                                errors={this.state.errors}
                            />
                        );
                    }
                    }
                })()}
                {this.state.firstError && `error with the ${this.state.firstError} field`}
            </main>
        );
    }
}

Promo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Promo));
