// src/ui/theme/index.js

import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#344955', testFail: '#f44336' , testPass: '#4caf50' },
  secondary: { main: '#F9AA33' },
};
const themeName = 'Oxford Blue Sea Buckthorn Addax';

export default createMuiTheme({
	palette,
	themeName,
	typography: {
    	useNextVariants: true,
  	},
  });
